.user-box {
	background-color: white;
	border-radius: 10px 0 0 0;
	box-shadow: var(--box-shadow);
	position: relative;
}

/* search-bar style start  */
.user-box .search {
	display: flex;
	align-items: center;
	align-self: center;
	background-color: #edf6f9;
	padding: 13px 11px;
}

.user-box .search i {
	font-size: 21px;
	margin: 3px 0 0 10px;
	color: #738290;
}

.user-box .search input {
	margin: 0 0 0 11px;
	width: 100%;
	background-color: transparent;
	border: none;
	outline: none;
}
/* search-bar style end  */

.user-box .user-content {
	overflow-x: hidden;
	overflow-y: auto;
	height: 79vh;
}

.user-box .user-content#student {
	height: 87vh;
}

.user-box .user {
	display: flex;
	align-items: center;
}

.user-box .user:hover {
	background-color: var(--hover-bg-color);
}

.user-box .user img {
	margin: 0 0 0 10px;
	cursor: pointer;
	outline: 2px solid black;
}

.user-box .user section {
	flex-grow: 1;
	padding: 15px 10px;
	border-bottom: 1px solid #dad7cd;
	cursor: pointer;
	overflow: hidden;
}

.user-box .user section {
	flex-grow: 1;
	padding: 15px 10px;
	border-bottom: 1px solid #dad7cd;
	cursor: pointer;
	overflow: hidden;
}

.user-box .user .above {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.user-box .user .above h6 {
	margin: 3px 0 0 0;
	font-weight: 600;
	letter-spacing: 0.5px;
	word-spacing: 4px;
	font-size: 17px;
	max-width: 70%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;
}

.user-box .user .above span {
	margin: 4px 0 0 0;
	font-size: 13px;
}
.user-box .user .down {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 15px;
}

/* for search-box start  */
.user-box #search-box {
	position: absolute;
	top: 61px;
	left: 8%;
	height: -moz-fit-content;
	height: fit-content;
	width: 90%;
	background: #94d2bd;
	background-color: white;
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	overflow: hidden;
	border-bottom: 1px solid #dad7cd;
	z-index: 10;
}

.user-box #search-box .user:hover {
	background-color: #62baea;
	color: white;
	transition: all 0.5s;
}

/* for search-box end  */
