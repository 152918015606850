.left .tab-container #highlight-left-sidebar {
	content: "";
	display: block;
	top: 0;
	left: 0;
	width: 0.5rem;
	height: 100%;
	position: absolute;
}

.left .tab-container .dashboard-active {
	background-color: hsl(252, 30%, 95%);
	position: relative;
}

.left .tab-container .group-chat-active {
	background-color: hsl(252, 30%, 95%);
	position: relative;
}

/* .software-setup-active style start  */
.left .tab-container .software-setup-active {
	background-color: hsl(252, 30%, 95%);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	cursor: default;
}

.left .tab-container .software-setup-inactive {
	position: relative;
}

.left .tab-container .software-setup-inactive::after {
	content: "";
	position: absolute;
	right: 22px;
	top: 26px;
	z-index: 1;
	width: 10px;
	height: 10px;
	border: 2.4px solid hsl(252deg 75% 60%);
	border-top: 2.4px solid #fff;
	border-right: 2.4px solid #fff;
	transform: rotate(-45deg);
	transition: 0.5s;
	pointer-events: none;
}

.left .tab-container .software-setup-active::after {
	content: "";
	position: absolute;
	right: 22px;
	top: 31px;
	z-index: 1;
	width: 10px;
	height: 10px;
	border: 2.4px solid hsl(252deg 75% 60%);
	border-top: 2.4px solid #f0eef6;
	border-right: 2.4px solid #f0eef6;
	transition: 0.5s;
	pointer-events: none;
	transform: rotate(-225deg);
}

.left .tab-container #software-setup-title {
	display: flex;
	align-items: center;
}

.left .tab-container .software-setup-inactive > .option-container {
	display: none;
}

.left .tab-container .software-setup-active > .option-container {
	display: block;
	margin: 15px 0 0 15px;
	overflow-y: auto;
	width: calc(100% - 25px);
	height: max-content;
	max-height: 115px;
}

.left .tab-container .software-setup-active > .option-container > .option {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.tab-container .software-setup-active > .option-container > .option#active {
	color: #073b4c;
	pointer-events: none;
}

.software-setup-active > .option-container > .option > .fa-circle {
	font-size: 7px;
	color: #023047;
}

.software-setup-active > .option-container > .option > div {
	margin-left: 15px;
	font-weight: 500;
	padding: 3px 0;
}

.software-setup-active > .option-container > .option#active > div {
	font-weight: 600;
}
/* .software-setup-active style end  */

.left .tab-container .appointment-active {
	background-color: hsl(252, 30%, 95%);
	position: relative;
}

.left .tab-container .advisor-active {
	background-color: hsl(252, 30%, 95%);
	position: relative;
}
