.advisor-info-container .layout .wrapper {
	background-color: white;
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	position: relative;
}

/* profile-img style start  */
.advisor-info-container .advisor-profile {
	display: flex;
	justify-content: center;
	align-items: center;
}

.advisor-info-container .advisor-profile .img-wrapper {
	margin: 30px 0 0 0;
}
.advisor-info-container .advisor-profile img {
	width: 200px;
	height: 200px;
	aspect-ratio: 1/1;
	object-fit: cover;
	object-position: top center;
	border-radius: 50%;
	border: 3px solid white;
}

.advisor-info-container .advisor-profile img.animation {
	animation: float 6s ease-in-out infinite;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translateY(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
		transform: translateY(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translateY(0px);
	}
}

/* profile-img style end  */

/* input field style start  */
.advisor-info-container .advisor-info {
	margin: 10px 0 0 0;
	padding-bottom: 19px;
}

.advisor-info-container .advisor-info .info {
	display: flex;
	justify-content: center;
}

.advisor-info-container .advisor-info span {
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding: 10px 20px;
	margin: 15px 20px;
	font-weight: 500;
	flex-basis: 40%;
	position: relative;
	display: flex;
	align-items: center;
}

.advisor-info-container .advisor-info span label {
	cursor: pointer;
}

.advisor-info-container .advisor-info span input {
	border: none;
	outline: none;
	padding-left: 7px;
	font-weight: 600;
	color: #006d77;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;
}

.advisor-info-container .advisor-info #name input {
	max-width: 200px;
}

.advisor-info-container .advisor-info #email input {
	max-width: 205px;
	text-transform: none;
}

.advisor-info-container .advisor-info #phone-number input {
	max-width: 190px;
	margin-left: 5px;
}

.advisor-info-container .advisor-info #phone-number h6 {
	display: inline;
	font-weight: 600;
	color: #006d77;
	margin: 0;
}

.advisor-info-container .advisor-info #department {
	text-transform: uppercase;
	max-width: 150px;
}

.advisor-info-container .advisor-info #active-status {
	display: flex;
	position: relative;
}

.advisor-info-container .advisor-info #active-status #display {
	width: 50%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 1;
	font-size: 15px;
	text-transform: none !important;
}

.advisor-info-container .advisor-info #active-status > div::after {
	content: "";
	position: absolute;
	width: 12px;
	height: 12px;
	border: 2px solid black;
	border-radius: 50%;
	background: #29bf12;
	top: 10px;
	right: 5px;
	animation: identifier 3s linear infinite;
}

@keyframes identifier {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	50% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0);
		opacity: 0;
	}
}

#not-found-advisor {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
}

/* input field style end  */

/* media query start  */
@media screen and (max-width: 1230px) {
	.advisor-info-container .advisor-info span {
		flex-basis: 44%;
	}
}

@media screen and (max-width: 950px) {
	.advisor-info-container .advisor-info .info {
		padding: 0 30px;
	}

	.advisor-info-container .advisor-info span {
		flex-basis: 100%;
	}
}

@media screen and (max-width: 500px) {
	.advisor-info-container .advisor-info .info {
		margin: 10px 0 0 0;
		padding: 0 10px;
	}

	.advisor-info-container .advisor-info span input {
		padding-left: 4px;
	}
}
