.message-box .header-container {
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.message-box .selected-user {
	padding: 8px 15px;
	display: flex;
	align-items: center;
	flex-grow: 1;
	overflow: hidden;
}

.message-box .selected-user img {
	width: 45px;
	min-width: 45px;
	height: 45px;
	min-height: 45px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	overflow: hidden;
	object-fit: cover;
	object-position: center;
	cursor: pointer;
}

.message-box .selected-user h6 {
	margin: 0 0px 0 12px;
	font-weight: 600;
	letter-spacing: 0.5px;
	word-spacing: 4px;
	font-size: 20px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--selected-user-color);
	text-transform: capitalize;
}

.message-box .header-container span #three-dot {
	margin: 0 20px 0 60px;
}

.message-box .header-container span i {
	font-size: 22px;
	opacity: 0.7;
	cursor: pointer;
}

/* style for dropdown start */
.header-container .header-dropdown {
	position: relative;
}

.header-container .header-dropdown ul {
	position: absolute;
	top: 28px;
	right: 0px;
	background-color: white;
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	z-index: 10;
	width: 190px;
	padding: 0;
	overflow: hidden;
}

.header-container .header-dropdown ul li {
	list-style-type: none;
	padding: 15px 0;
	font-weight: 500;
	cursor: pointer;
}

.header-container .header-dropdown ul li:first-child {
	border-bottom: 1px solid rgb(216, 211, 211);
}

.header-container .header-dropdown ul li > span {
	font-size: 15.5px;
}

.header-container .header-dropdown ul li i {
	margin: 0 10px 0 20px;
	font-size: 16px;
	opacity: 0.8;
}

.header-container .header-dropdown ul li:first-child i {
	color: #219ebc;
}
.header-container .header-dropdown ul li:first-child:hover i {
	color: white;
}
.header-container .header-dropdown ul li:last-child i {
	color: orangered;
}
/* style for dropdown end */

/* view-container start  */
.header-container .view-container {
	position: absolute;
	z-index: 9999;
	top: 63px;
	height: 79vh;
	background-color: white;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

/* icon style start  */
.header-container .view-container .icon {
	font-size: 25px;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.header-container .view-container .icon span:hover i {
	transform: scale(0.93);
}

.header-container .view-container .icon span:first-child {
	border-radius: 50%;
	padding: 4px 9px;
	color: #228bc5;
	background-color: #eaefff;
	margin-right: 20px;
}

.header-container .view-container .icon span:first-child i {
	margin-left: 4px;
}

.header-container .view-container .icon span:last-child {
	border-radius: 50%;
	padding: 5px 11px;
	color: #f3722c;
	background-color: #fff2e5;
}

.header-container .view-container .icon span#not-display {
	display: none;
}
/* icon style end  */

/* view-group-container start  */
.view-container .view-group-container .group-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.view-group-container .group-info .img-wrapper {
	position: relative;
	margin: 20px 0 0 0;
}
.view-group-container .group-info img {
	width: 150px;
	height: 150px;
	aspect-ratio: 1/1;
	object-fit: cover;
	object-position: top center;
	border-radius: 50%;
	border: 3px solid white;
}

.view-group-container .group-info img.animation {
	animation: float 6s ease-in-out infinite;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translateY(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
		transform: translateY(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translateY(0px);
	}
}

.view-group-container .group-info .change-img {
	position: absolute;
	bottom: 16px;
	right: 5px;
	z-index: 10;
	border-radius: 50%;
	border: 3px solid white;
	color: #3a86ff;
	background-color: white;
	cursor: pointer;
}

.view-group-container .group-info .change-img i {
	font-size: 24px;
	cursor: pointer;
}

.group-info #group-name {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80%;
	margin-top: 15px;
	flex-direction: column;
}

.group-info #group-name input {
	border: none;
	outline: none;
	padding-left: 5px;
	font-weight: 600;
	color: #006d77;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;
	text-align: center;
	font-size: 20px;
	width: 100%;
}

.group-info #group-name input#active {
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding: 10px 20px;
	outline: 3px solid #c2daff;
	text-align: start;
}

.group-info #group-name .group-edit-btn {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin: 20px 0;
}

.group-info #group-name .group-edit-btn button {
	padding: 7px 20px;
	font-size: 17px;
	font-weight: 500;
}

.group-info #group-name .group-edit-btn button:hover {
	transform: scale(0.98);
}

.group-info #group-name .group-edit-btn button:first-child {
	margin-right: 30px;
}

/* view-group-container end  */

/* group-member display start  */
.view-group-container .display-members {
	padding: 11px 12px 11px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
	grid-gap: 10px;
}

.view-group-container .display-members .member {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.view-group-container .display-members .member img {
	border-radius: 10px;
	object-fit: cover;
	object-position: center;
}

.view-group-container .display-members .member h6 {
	margin: 5px 0;
	text-align: center;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #006d77;
	font-weight: 600;
}

/* group-member display end  */

/* view-attachment-container start  */

.view-container .view-attachment-container {
	padding: 11px 12px 11px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 15px;
}

.view-container .view-attachment-container .attachment {
	width: 100%;
	height: 300px;
	border-radius: 10px;
	overflow: hidden;
}

.view-container .view-attachment-container a {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	color: black;
	text-decoration: none;
	font-weight: 500;
	color: #006d77;
}

.view-container .view-attachment-container a:hover {
	text-decoration: underline;
}

.view-container .view-attachment-container .attachment img {
	width: 100%;
	height: 90%;
	object-fit: contain;
	object-position: center;
	border-radius: 10px;
	margin-bottom: 5px;
}

.view-container .view-attachment-container .attachment img.cover {
	object-fit: cover;
}

.view-attachment-container .attachment .audio-container {
	width: 100%;
	height: 80%;
	border-radius: 10px;
}

.view-container .view-attachment-container .attachment video {
	width: 100%;
	height: 90%;
	object-fit: cover;
	object-position: center;
	border-radius: 10px;
}

.view-attachment-container .empty-attachment {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	transform: translate(55%);
}

.view-attachment-container .empty-attachment p {
	font-weight: 600;
	font-size: 16px;
	color: red;
}

/* view-attachment-container end  */

/* view-container end  */

/* back-arrow style start */
.header-container .back-arrow {
	padding-left: 15px;
}
/* back-arrow style end */
