.c-appointment-container {
	background-color: white;
	border-radius: var(--card-border-radius);
	box-shadow: var(--box-shadow);
	max-height: 86vh;
	overflow: auto;
}

.c-appointment-container h5 {
	margin-top: 30px;
	word-spacing: 5px;
	padding: 0 0 0 20px;
	border-left: 5px solid #3366ff;
	align-self: center;
	opacity: 0.9;
}

.c-appointment-container .input-box {
	padding: 10px 25px;
}

.input-box .division {
	width: 100%;
	display: inline-flex;
	margin: 15px 0;
}

.input-box .division td:first-child {
	flex-basis: 15%;
	font-weight: 500;
}

.input-box .division td:last-child {
	flex-basis: 85%;
	flex-grow: 1;
}

.input-box .division input {
	padding: 9px 20px;
	border: 1px solid #d3dfea;
	border-radius: 10px;
}

.input-box .division input:focus {
	outline: 4px solid #c2daff;
	border-radius: 10px;
}

.input-box .division .input-field {
	width: 100%;
}

.input-box .division #description {
	border: 1px solid #d3dfea;
	padding: 15px 20px 15px 20px;
	line-height: 200%;
	letter-spacing: 0.5px;
	word-wrap: break-word;
	word-spacing: 0.2rem;
	overflow-y: auto;
	border-radius: 10px;
}

.input-box .division #description:focus {
	outline: 4px solid #c2daff;
}

/* attachment section style  */
.input-box .division .kiHXWb {
	flex-grow: 1;
	height: 80px;
	background-color: #eaefff;
	min-width: 100%;
}

.input-box .fVfcRr > span {
	flex-grow: 1;
	padding: 5px;
	font-size: 18px;
	border: none;
	display: flex;
	justify-content: center;
}

.input-box .cXBrtO span {
	-webkit-text-decoration: none;
	text-decoration: none;
	margin-right: 5px;
}

.input-box .fVfcRr .file-types {
	display: none;
}

.input-box svg {
	display: none;
}

.input-box .submit-btn {
	display: flex;
	justify-content: flex-end;
	margin: 20px 0 0 0;
}

.input-box .submit-btn button {
	font-weight: 500;
	margin: -5px 30px 15px 0;
	padding: 7px 20px;
	font-size: 18px;
}

.input-box .submit-btn button:hover {
	transform: scale(0.97);
}

/* for scrollbar style  */
.c-appointment-container::-webkit-scrollbar-thumb {
	background-color: #7400b8;
}

.c-appointment-container::-webkit-scrollbar {
	width: 4px;
}

/* media query start  */
@media screen and (max-width: 1150px) {
	.input-box .division td:first-child {
		flex-basis: 18%;
	}

	.input-box .division td:last-child {
		flex-basis: 81%;
	}
}

@media screen and (max-width: 768px) {
	.input-box .cXBrtO span {
		display: none;
	}
}

@media screen and (max-width: 700px) {
	.input-box .division td:first-child {
		flex-basis: 20%;
	}

	.input-box .division td:last-child {
		flex-basis: 79%;
	}
}

@media screen and (max-width: 650px) {
	.input-box .division td:first-child {
		flex-basis: 25%;
	}

	.input-box .division td:last-child {
		flex-basis: 74%;
	}

	.c-appointment-container .input-box {
		padding: 10px 10px;
	}
}

@media screen and (max-width: 550px) {
	.c-appointment-container h5 {
		margin-top: 10px;
	}

	.input-box .division {
		margin: 7px 0;
	}

	.input-box .division td:first-child {
		flex-basis: 28%;
	}

	.input-box .division td:last-child {
		flex-basis: 67%;
	}
}

@media screen and (max-width: 465px) {
	.input-box .division td:first-child {
		flex-basis: 30%;
	}

	.input-box .division td:last-child {
		flex-basis: 70%;
	}
}

@media screen and (max-width: 390px) {
	.input-box .division td:first-child {
		flex-basis: 38%;
	}

	.input-box .division td:last-child {
		flex-basis: 62%;
	}
}
