.profile-edit-container {
	position: absolute;
	top: 0;
	width: 100%;
}

.profile-edit-container .layout {
	display: flex;
	justify-content: center;
}

.profile-edit-container .layout .wrapper {
	background-color: white;
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	position: relative;
	padding: 10px 0;
}

.profile-edit-container .layout #blur {
	filter: blur(1.5px);
	background-color: white;
}

/* icon style start  */
.profile-edit-container .wrapper .icon {
	font-size: 25px;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.profile-edit-container .wrapper .icon span:hover i {
	transform: scale(0.93);
}

.profile-edit-container .wrapper .icon span:first-child {
	border-radius: 50%;
	padding: 5px 12px;
	color: red;
	background-color: #fff2e5;
	margin-right: 20px;
}

.profile-edit-container .wrapper .icon span:first-child i {
	margin-left: 4px;
}

.profile-edit-container .wrapper .icon span#when-not-admin {
	display: none;
}

.profile-edit-container .wrapper .icon span:nth-child(2) {
	border-radius: 50%;
	padding: 5px 7px;
	color: #228bc5;
	background-color: #eaefff;
	margin-right: 20px;
}

.profile-edit-container .wrapper .icon span:nth-child(2) i {
	margin-left: 4px;
}

.profile-edit-container .wrapper .icon span:last-child {
	border-radius: 50%;
	padding: 5px 11px;
	color: #f3722c;
	background-color: #fff2e5;
}
/* icon style end  */

/* profile-img style start  */
.profile-edit-container .curr-user-profile {
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-edit-container .curr-user-profile .img-wrapper {
	position: relative;
	margin: 15px 0 0 0;
}
.profile-edit-container .curr-user-profile img {
	width: 200px;
	height: 200px;
	aspect-ratio: 1/1;
	object-fit: cover;
	object-position: top center;
	border-radius: 50%;
	border: 2px solid rgb(189, 185, 185);
}

.profile-edit-container .curr-user-profile img.animation {
	animation: float 6s ease-in-out infinite;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translateY(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
		transform: translateY(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
		transform: translateY(0px);
	}
}

.profile-edit-container .curr-user-profile .change-img {
	position: absolute;
	bottom: 16px;
	right: 18px;
	z-index: 10;
	border-radius: 50%;
	border: 3px solid white;
	color: #3a86ff;
	background-color: white;
	cursor: pointer;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: 1.5px solid rgb(220, 247, 247);
}

.profile-edit-container .curr-user-profile .change-img i {
	font-size: 17px;
	cursor: pointer;
}
/* profile-img style end  */

/* input field style start  */
.profile-edit-container .curr-user-info {
	margin: 10px 0 0 0;
	padding: 0 40px;
}

.profile-edit-container .curr-user-info .info {
	display: flex;
	justify-content: center;
}

.profile-edit-container .curr-user-info span {
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	padding: 10px 20px;
	margin: 13px 20px;
	font-weight: 500;
	flex-basis: 40%;
	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.profile-edit-container .curr-user-info span:focus {
	outline: 4px solid #c2daff;
}

.profile-edit-container .curr-user-info span label {
	cursor: pointer;
}

.profile-edit-container .curr-user-info span input {
	border: none;
	outline: none;
	padding-left: 5px;
	font-weight: 600;
	color: #006d77;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;
}

.profile-edit-container .curr-user-info span .input-text {
	margin: 0 0 0 20px;
	display: inline-block;
	white-space: nowrap;
}

.profile-edit-container .curr-user-info span .input-text-hide {
	display: none;
}

.profile-edit-container .curr-user-info .outline-style {
	outline: 1px solid rgb(189, 185, 185);
}

.profile-edit-container .curr-user-info #outline-style {
	outline: 1px solid rgb(189, 185, 185);
}

.profile-edit-container .curr-user-info #email input {
	text-transform: none;
}

.profile-edit-container .curr-user-info .remove-pd {
	padding: 10px 0 !important;
}

.profile-edit-container .curr-user-info .remove-pd input {
	padding: 0 20px !important;
}

.profile-edit-container .curr-user-info #current-p input {
	margin-left: 5px;
}

.profile-edit-container .curr-user-info #current-p input:focus {
	background-color: transparent;
}

.profile-edit-container .curr-user-info #current-p {
	outline: 1px solid rgb(189, 185, 185);
}

.profile-edit-container .curr-user-info #phone-number {
	outline: 1px solid rgb(189, 185, 185);
}

.profile-edit-container .curr-user-info #phone-number-inactive {
	white-space: nowrap;
}

.profile-edit-container .curr-user-info #phone-number:focus {
	background-color: transparent;
}

.profile-edit-container .curr-user-info #phone-number h6 {
	display: inline;
	font-weight: 600;
	color: #006d77;
	margin: 0;
}

.profile-edit-container .curr-user-info #phone {
	margin-left: 5px;
}

.profile-edit-container .profile-btn {
	display: flex;
	justify-content: flex-end;
	padding: 0 !important;
	box-shadow: none !important;
}

.profile-edit-container .profile-btn button {
	padding: 7px 20px;
	font-size: 17px;
	font-weight: 500;
	width: 62.262px;
	height: 25.5px;
	box-sizing: content-box;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
}

.profile-edit-container .profile-btn button#cancel {
	margin-right: 20px;
}

.profile-edit-container .profile-btn button:hover {
	transform: scale(0.97);
}

.profile-edit-container .profile-btn button:active {
	transform: scale(1.1);
}

.profile-edit-container .profile-btn button:focus {
	box-shadow: none;
}

.profile-edit-container .profile-btn i {
	font-size: 21px;
}

/* input field style end  */

/* for delete conform popup style start  */
.profile-edit-container .layout .wrapper .conformation-popup {
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 5px 5px red;
	padding: 35px 35px 10px 35px;
	position: absolute;
	left: 25%;
	top: 40%;
	z-index: 9999;
}

.layout .wrapper .conformation-popup .delete-controller {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}

.layout .wrapper .conformation-popup .delete-controller button:first-child {
	margin-right: 30px;
}
/* for delete conform popup style end  */

/* password toggle eye style start  */
.curr-user-info .password-field {
	position: relative;
}

.curr-user-info .password-field #eye {
	position: absolute;
	top: 25%;
	right: 20px;
}

.curr-user-info .password-field #eye i {
	opacity: 0.7;
}

/* password toggle eye style end  */

/* range container style start  */
.curr-user-info .range-container {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-weight: 600;
	font-size: 18px;
	overflow: hidden;
	padding-left: 20px;
}

.curr-user-info .range-container h6 {
	margin: 0;
	width: fit-content;
	font-weight: 600;
	font-size: 16px;
}

.curr-user-info .range-container .range-fields {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
}

.curr-user-info .range-container input {
	border: none;
	outline: none;
	width: 40%;
	text-align: center;
}
/* range container style end  */

/* media query start  */
@media screen and (max-width: 1230px) {
	.profile-edit-container .curr-user-info span {
		flex-basis: 44%;
	}
}

@media screen and (max-width: 950px) {
	.profile-edit-container .curr-user-info span {
		flex-basis: 100%;
	}

	.profile-edit-container .curr-user-info #current-p {
		order: 1;
	}

	.profile-edit-container .curr-user-info .new-password-field {
		order: 2;
	}

	.profile-edit-container .curr-user-info .profile-btn {
		order: 3;
	}
}

@media screen and (max-width: 700px) {
	.profile-edit-container .wrapper .icon {
		display: flex;
		flex-direction: column-reverse;
		right: 0px;
		top: 10px;
	}
	.profile-edit-container .wrapper .icon span {
		margin-bottom: 15px;
	}

	.profile-edit-container .wrapper .icon span:first-child {
		order: 3;
	}
	.profile-edit-container .wrapper .icon span:nth-child(1) {
		order: 1;
	}
	.profile-edit-container .wrapper .icon span:last-child {
		order: 2;
		align-self: center;
		margin-right: 20px;
	}
}

@media screen and (max-width: 600px) {
	.profile-edit-container .curr-user-profile img {
		width: 170px;
		height: 170px;
	}

	.profile-edit-container .curr-user-info span {
		margin: 15px 10px;
	}
}

@media screen and (max-width: 500px) {
	.profile-edit-container .curr-user-info {
		margin: 10px 0 0 0;
		padding: 0 20px;
	}
	.profile-edit-container .curr-user-info span {
		margin: 14px 10px;
	}
}

@media screen and (max-width: 400px) {
	.profile-edit-container .curr-user-info span {
		padding: 10px 10px;
		margin: 13px 10px;
	}

	.profile-edit-container .curr-user-info span .input-text {
		margin: 0 0 0 10px;
	}
}

/* media query for conformation popup  */
@media screen and (max-width: 970px) {
	.profile-edit-container .layout .wrapper .conformation-popup {
		top: 20%;
		left: 22%;
	}
}

@media screen and (max-width: 830px) {
	.profile-edit-container .layout .wrapper .conformation-popup {
		top: 20%;
		left: 17%;
	}
}

@media screen and (max-width: 740px) {
	.profile-edit-container .layout .wrapper .conformation-popup {
		padding: 20px 20px 10px;
		left: 15%;
	}
}

@media screen and (max-width: 660px) {
	.profile-edit-container .layout .wrapper .conformation-popup {
		padding: 10px 20px;
		margin: 0 20px;
		left: 7%;
	}
}

@media screen and (max-width: 600px) {
	.profile-edit-container .layout .wrapper .conformation-popup {
		left: 0%;
	}
}

/* active-status style start */
.profile-edit-container .curr-user-info #active-status {
	position: relative;
}

.profile-edit-container .curr-user-info #active-status-dropdown {
	outline: 1px solid rgb(189, 185, 185);
	position: relative;
}

.profile-edit-container .curr-user-info #active-status input {
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 1;
}

.profile-edit-container .curr-user-info #active-status > div::after {
	content: "";
	position: absolute;
	width: 12px;
	height: 12px;
	border: 2px solid black;
	border-radius: 50%;
	background: #29bf12;
	top: 10px;
	right: 5px;
	animation: identifier 3s linear infinite;
}

@keyframes identifier {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	50% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0);
		opacity: 0;
	}
}
/* active-status style end */
