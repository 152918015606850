.curr-user-info .year-container {
	display: inline-block;
}

.curr-user-info .year-container input {
	cursor: pointer;
	background-color: #fff;
	border: none;
	outline: none;
	border-radius: 5px;
	font-weight: 600;
	font-size: 18px;
	padding: 4px 6px;
	text-align: center;
}

/* date-picker modify start  */
.curr-user-info
	.year-container
	.react-calendar__decade-view
	.react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 1em 0.5em;
}

.curr-user-info .year-container .react-date-picker__calendar {
	inset: auto auto 190% -110% !important;
}

.curr-user-info .year-container .react-date-picker__wrapper {
	width: 100px;
	border: none !important;
}

.curr-user-info .year-container .react-date-picker__calendar .react-calendar {
	border-radius: 5px;
}

.curr-user-info .year-container .react-calendar__navigation__label__labelText,
.curr-user-info
	.year-container
	.react-calendar__navigation__label__labelText--from {
	padding: 0;
	box-shadow: none;
	margin: 0;
	text-align: center;
	border-radius: 0;
	display: inline-block;
	background-color: transparent !important;
}

.curr-user-info .year-container .react-calendar__navigation__label:hover {
	background-color: transparent !important;
}

.curr-user-info .year-container .react-calendar__navigation {
	margin-bottom: 0;
}
/* date-picker modify end  */
