.message-box {
	flex-grow: 1;
	border-radius: 0 10px 0 0;
	box-shadow: var(--box-shadow);
	position: relative;
	overflow: hidden;
	height: 100%;
}

/* welcome chat start  */
.welcome-chat {
	height: 88vh;

	background-image: url("./welcome-chat.jpg");

	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	background-color: rgb(255, 255, 255);
	filter: brightness(99%);
}

.welcome-chat h3 {
	color: #058c42;
}
.welcome-chat h2 {
	padding-bottom: 60px;
	font-weight: 600;
	color: #007f5f;
}

/* welcome chat end  */
