.signup-container .right .depart-container {
	position: relative;
	width: 48%;
	margin: -5px 0 0 0;
}

.signup-container .right .depart-container::before {
	content: "";
	position: absolute;
	right: 12px;
	top: 15px;
	z-index: 1;
	width: 8px;
	height: 8px;
	border: 2px solid #333;
	border-top: 2px solid #fff;
	border-right: 2px solid #fff;
	transform: rotate(-45deg);
	transition: 0.5s;
	pointer-events: none;
}

.signup-container .right .depart-container.active::before {
	top: 20px;
	transform: rotate(-225deg);
}

.signup-container .right .depart-container input {
	cursor: pointer;
	background-color: #fff;
	border: none;
	outline: none;
	box-shadow: 0px 1px 5px rgb(0 0 0 / 50%);
	padding: 7px 14px;
	border-radius: 5px;
	font-weight: 600;
	font-size: 18px;
	width: 100%;
}

.signup-container .right .depart-container input::placeholder {
	color: #757575;
	font-weight: 500;
}

.signup-container .right .depart-container input:focus {
	outline: 4px solid #c2daff;
}

.signup-container .right .depart-container .option {
	position: absolute;
	bottom: 54px;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 1px 6px rgb(0 0 0 / 50%);
	border-radius: 10px;
	overflow: hidden;
	display: none;
	z-index: 1;
	font-weight: 500;
}

.signup-container .right .depart-container.active .option {
	display: block;
}

.signup-container .right .depart-container .option div {
	padding: 7px 10px;
	cursor: pointer;
}

.signup-container .right .depart-container .option div:hover {
	background-color: #62baea;
	color: #fff;
	transition: all 0.3s;
}

.signup-container .right .depart-container .option div i {
	position: relative;
	top: 0px;
	font-size: 1em;
	margin: 0 10px 0 0;
}
