.total-main-container {
	position: absolute;
	top: 0;
	z-index: 10;
}

.total-main-container .total-wrapper {
	display: flex;
	justify-content: center;
}

.total-wrapper .total-container {
	background-color: white;
	box-shadow: var(--box-shadow);
	border-radius: var(--card-border-radius);
	position: relative;
}

.total-wrapper .total-container .icon {
	padding: 2px 9px;
	border-radius: 50%;
	background-color: #fff2e5;
	color: #f3722c;
	font-size: 28px;
	position: absolute;
	top: 10px;
	right: 23px;
	cursor: pointer;
}

.total-wrapper .total-container .icon:hover {
	transform: scale(0.98);
}

.total-wrapper .total-container .icon:active {
	transform: scale(0.95);
}

.total-wrapper .total-container .icon > i {
	margin: 6px 1px 0px 1px;
}

.total-container .header {
	display: flex;
	justify-content: center;
	padding: 0 15px;
	position: relative;
}

.total-container .header .user-search {
	display: flex;
	align-items: center;
	align-self: center;
	background-color: #f0eef6;
	width: 400px;
	border-radius: 30px;
	padding: 0 11px;
	height: 50px;
	position: absolute;
	top: 12px;
	left: 20px;
}

.total-container .header .user-search i {
	font-size: 20px;
	margin: 3px 0 0 10px;
	color: #738290;
}

.total-container .header .user-search input {
	margin: 0 0 0 11px;
	width: 100%;
	background-color: transparent;
	border: none;
	outline: none;
	font-weight: 500;
	font-size: 17px;
}

.total-container .header h2 {
	color: rgb(31, 132, 20);
	margin: 0;
	padding: 20px 0px;
	font-weight: 600;
	letter-spacing: 1px;
	word-spacing: 10px;
}

/* table style start  */
.total-wrapper .table-container {
	min-height: fit-content;
	max-height: 73vh;
	overflow: auto;
	padding: 0 15px;
	margin-right: 4px;
	margin-left: 4px;
}

.total-container .table-container table thead tr th {
	border-right: 0.5px solid #dee2e6;
	text-align: center;
	cursor: pointer;
	background-color: #198510;
	color: white;
	opacity: 1;
	font-weight: 500;
	pointer-events: none;
}

.total-container .table-container table thead tr th:first-child {
	border-radius: 10px 0 0 10px;
}

.total-container .table-container table thead tr th:last-child {
	border-radius: 0px 10px 10px 0px;
}

.total-container .table-container table thead tr th:hover {
	background-color: var(--project-name-color);
	transition: all 0.5s;
}

.total-container .table-container table tbody #profile-img {
	width: 50px;
	min-width: 50px;
	height: 50px;
	min-height: 50px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	overflow: hidden;
	object-fit: cover;
	object-position: center;
	margin-right: 7px;
	outline: 1.5px solid rgb(189, 185, 185);
}

/* table's row style start  */
.total-container .table-container table tbody tr {
	cursor: pointer;
}

.total-container .table-container table tbody tr:hover {
	background-color: #f2f2f2;
}

.total-container .table-container table tbody tr td {
	text-align: center;
}

.total-container .table-container table tbody input {
	border: none;
	outline: none;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	padding: 10px 0;
	background-color: transparent;
	cursor: pointer;
}

.total-container .table-container table #count {
	text-align: center;
	padding-top: 20px;
	font-weight: 600;
	color: #4a4e69;
}

.total-container .table-container table #name {
	display: flex;
	align-items: center;
}

.total-container .table-container table #name input {
	width: 100% !important;
	text-transform: capitalize;
}

.total-container .table-container table #id input {
	width: 100px;
}

.total-container .table-container table #email input {
	width: initial;
}

.total-container .table-container table #department input {
	width: 101px;
	text-transform: uppercase;
}

.total-container .table-container table #range input {
	width: 100px;
}

.total-container .table-container table #semester input {
	width: 75px;
}

.total-container .table-container table #year input {
	width: 85px;
}

.total-container .table-container table #gender input {
	width: 60px;
}

.total-container .table-container table #update-date input {
	width: 100px;
}

/* media query start  */
@media screen and (max-width: 1300px) {
	.total-container .header .user-search {
		width: 300px;
	}
}

@media screen and (max-width: 1020px) {
	.total-container .header .user-search {
		width: 230px;
	}
}

@media screen and (max-width: 850px) {
	.total-container .header .user-search {
		width: 50px;
		height: 50px;
		background-color: white;
		box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
		justify-content: center;
		margin-right: 80%;
		cursor: pointer;
	}

	.total-container .header .user-search.active {
		margin: 0 10% 0 0;
		width: 40%;
		transition: all 0.5s;
	}
}

@media screen and (max-width: 600px) {
	.total-container .header .user-search.active {
		width: 70%;
	}
}

@media screen and (max-width: 400px) {
	.total-container .header h2 {
		font-size: 21px;
	}
}
