.navbar-main-container {
	background-color: #fff;
	display: flex;
	justify-content: center;
	padding: 10px 0;
}

.navbar-main-container .navbar-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

/* left start  */
.navbar-container .left {
	display: flex;
	align-items: center;
}

.navbar-container .left #main-logo {
	width: 180px;
	height: 60px;
	max-width: 180px;
	max-height: 60px;
	min-width: 180px;
	min-height: 60px;
	overflow: hidden;
	object-fit: fill;
	object-position: center;
	cursor: pointer;
}

/* left-sidebar for small device style start  */
.navbar-container .left > .left-sidebar-container {
	display: none;
}

.navbar-container .left > .left-sidebar-container .within-left-sidebar-logo {
	display: none;
}

.navbar-container .left > .left-sidebar-container #for-separation {
	width: 100%;
	height: 0.1px;
	background-color: rgb(231, 234, 244);
	margin: 17px 0 10px;
	display: none;
}

.navbar-container .left > .left-sidebar-container .nav-links li {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500px;
	padding: 9px 0;
	cursor: pointer;
}

.navbar-container
	.left
	> .left-sidebar-container
	.nav-links
	li:not(.software-setup-item):hover {
	color: #a4c3b2;
}

/* for software-setup options dropdown start  */
.navbar-container
	.left
	> .left-sidebar-container
	.nav-links
	li.software-setup-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.navbar-container
	.left
	> .left-sidebar-container
	.nav-links
	li.software-setup-item::before {
	content: "";
	position: absolute;
	right: 10px;
	top: 15px;
	z-index: 1;
	width: 10px;
	height: 10px;
	border: 2.4px solid white;
	border-top: 2.4px solid #4f5162;
	border-right: 2.4px solid #4f5162;
	transform: rotate(-45deg);
	transition: 0.5s;
	pointer-events: none;
}

.navbar-container
	.left
	> .left-sidebar-container
	.nav-links
	li.software-setup-item#active::before {
	top: 19px;
	transform: rotate(-225deg);
}

.left-sidebar-container .nav-links li.software-setup-item > .option-container {
	display: block;
	margin: 5px 0 0 30px;
	width: calc(100% - 25px);
	height: max-content;
}

.left-sidebar-container
	.nav-links
	li.software-setup-item
	> .option-container
	> .option {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.left-sidebar-container
	.nav-links
	li.software-setup-item
	> .option-container
	> .option:hover {
	color: #a4c3b2;
}

.software-setup-item > .option-container > .option > .fa-circle {
	font-size: 7px;
	color: white;
}

.software-setup-item > .option-container > .option:hover > .fa-circle {
	color: #a4c3b2;
}

.software-setup-item > .option-container > .option > div {
	margin-left: 10px;
	font-weight: 500;
	padding: 2px 0;
}
/* for software-setup options dropdown end  */

.navbar-container .left > .left-sidebar-container .nav-links li > i {
	margin-right: 15px;
	font-size: 15.5px;
}

.left-sidebar-container .nav-links li #software-setup-content > i {
	margin-right: 15px;
	font-size: 15.5px;
}

/* animation for link  */
.ani-link-1 {
	animation: linkAnimation 0.5s ease forwards 0.5s;
}
.ani-link-2 {
	animation: linkAnimation 0.5s ease forwards 0.8s;
}
.ani-link-3 {
	animation: linkAnimation 0.5s ease forwards 1.1s;
}
.ani-link-4 {
	animation: linkAnimation 0.5s ease forwards 1.4s;
}
.ani-link-5 {
	animation: linkAnimation 0.5s ease forwards 1.7s;
}

@keyframes linkAnimation {
	from {
		opacity: 0;
		transform: translateX(-50px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

.navbar-container .left > .left-sidebar-container .burger {
	display: none;
	cursor: pointer;
	margin-right: 20px;
}

.navbar-container .left > .left-sidebar-container .burger div {
	width: 30px;
	height: 4px;
	margin: 5px 5px 5px 0px;
	transition: all 0.3s ease;
}

/* add cross line  */
.navbar-container .left > .left-sidebar-container .burger .line-1 {
	transform: rotate(-45deg) translate(-5px, 6px);
}

.navbar-container .left > .left-sidebar-container .burger .line-2 {
	opacity: 0;
}

.navbar-container .left > .left-sidebar-container .burger .line-3 {
	transform: rotate(45deg) translate(-6px, -8px);
}

@media screen and (max-width: 992px) {
	html {
		overflow-x: hidden;
	}

	.navbar-container .left > .left-sidebar-container {
		display: block;
	}

	.navbar-container .left > .left-sidebar-container .nav-links {
		width: 250px;
		height: fit-content;
		position: absolute;
		top: 70px;
		left: 0px;
		background-color: rgba(36, 38, 59, 0.8);
		transition: transform 0.5s ease-in;
		z-index: 100000;
		border-radius: 7px;
		margin: 0;
		transform: translateX(-350px);
		display: flex;
		flex-direction: column;
		padding: 20px 20px 20px 25px;
	}

	.navbar-container .left > .left-sidebar-container .nav-active {
		transform: translateX(0);
	}

	.navbar-container .left > .left-sidebar-container .nav-links li {
		opacity: 0;
	}

	.navbar-container .left > .left-sidebar-container .burger {
		display: block;
	}
}

@media screen and (max-width: 550px) {
	.navbar-container .left > .left-sidebar-container .nav-links {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0px;
		background-color: rgba(36, 38, 59, 0.8);
		transition: transform 0.5s ease-in;
		z-index: 100000;
		border-radius: 0px;
		margin: 0;
		transform: translateX(-110%);
		display: flex;
		flex-direction: column;
		padding: 20px 20px 20px 25px;
	}

	.navbar-container .left > .left-sidebar-container .nav-active {
		transform: translateX(0);
	}

	.navbar-container .left > .left-sidebar-container .nav-links li {
		padding: 12px 0;
		opacity: 0;
	}

	.navbar-container .left > .left-sidebar-container .within-left-sidebar-logo {
		display: inline-block;
	}

	.navbar-container .left > .left-sidebar-container #for-separation {
		display: block;
	}

	.navbar-container .left > .left-sidebar-container .burger#shift-right {
		position: absolute;
		top: 25px;
		right: -17px;
		z-index: 100000;
	}

	.navbar-container .left > .left-sidebar-container .burger#shift-right > div {
		background-color: #ddebe3 !important;
		transition: all 0.5s;
	}
}

@media screen and (max-width: 480px) {
	.navbar-container .left > .left-sidebar-container .burger {
		margin-right: 10px;
	}
}
/* left-sidebar for small device style end */
/* left end  */

/* middle start  */
.navbar-container .middle {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 40px;
}

.navbar-container .middle .total {
	color: #fff;
	font-weight: 500;
	padding: 10px 20px;
	font-size: 17px;
	border-radius: 6px;
	box-shadow: var(--box-shadow);
	cursor: pointer;
	text-align: center;
}

.navbar-container .middle .total:first-child {
	margin: 0px;
}

.navbar-container .middle .total:nth-child(2) {
	margin: 0 0 0 20px;
}

.navbar-container .middle .total:last-child {
	margin: 0 0 0 20px;
}
/* middle end  */

/* right start  */
.navbar-container .right {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar-container .right .icon-style {
	display: flex;
	justify-content: space-evenly;
	flex-grow: 1;
}

.navbar-container .right .icon-style span {
	position: relative;
}

.navbar-container .right .icon-style span i {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #738290;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	transition: all 0.5s;
	font-size: 22px;
	cursor: pointer;
}

.navbar-container .right .icon-style span i::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: -90px;
	left: 0;
	/* background-image: linear-gradient(#b1b2d4, #0ead69); */
	transition: transform 0.4s;
	transition: color 0.5s;
	z-index: -1;
}

.navbar-container .right .icon-style span i:hover::after {
	top: 0;
}

.navbar-container .right .icon-style span i:hover {
	transform: translateY(-5px);
	color: #faf3dd;
}

/* for notification badge style start  */
.navbar-container .right .icon-style span i div {
	width: fit-content !important;
	height: fit-content !important;
}

.right .icon-style span i .notification-count {
	top: -16px !important;
	right: -11px !important;
}

.right .notification-container {
	position: absolute;
	top: 58px;
	right: 0;
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	overflow-x: hidden;
	overflow-y: auto;
	height: -moz-fit-content;
	height: fit-content;
	max-height: 50vh;
	width: 443px;
	z-index: 999;
	padding: 0;
	margin: 0;
}

.right .no-notification {
	position: absolute;
	top: 58px;
	right: 0;
	background-color: white;
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	overflow-x: hidden;
	overflow-y: auto;
	width: 443px;
	z-index: 999;
	padding: 10px;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.right .no-notification h6 {
	margin: 0;
}

.right .notification-container .notification-display {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px 2px 5px 15px;
	border-bottom: 1px solid #dad7cd;
	width: 100%;
	position: relative;
}

.right .notification-container .notification-display:hover {
	background-color: var(--hover-bg-color);
}

.right .notification-container .notification-display > div {
	display: flex;
	padding: 15px 10px;
}

.notification-container .notification-display h6 {
	margin: 0 8px 0 0;
	align-self: center;
	font-weight: 500;
	letter-spacing: 0.5px;
	word-spacing: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #228bc5;
}

.notification-container .notification-display span {
	width: 228px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.notification-display .last-message {
	display: flex;
	flex-direction: column;
	padding: 5px 10px !important;
}

.notification-display .notification-time {
	position: absolute;
	top: -11px;
	right: 0;
	font-size: 14px;
}

/* for notification badge style end  */

.navbar-container .right img {
	cursor: pointer;
	outline: 1.5px solid rgb(189, 185, 185);
	box-shadow: var(--box-shadow);
}
/* right end  */

/* style for dropdown */
.navbar-container .nav-profile {
	position: relative;
}

.nav-profile ul {
	position: absolute;
	top: 58px;
	right: 0;
	background-color: white;
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	z-index: 10;
	width: 165px;
	padding: 0;
	overflow: hidden;
	animation: rotateX 0.5s ease-in-out forwards;
}

.nav-profile ul li {
	list-style-type: none;
	padding: 10px 0;
	font-weight: 500;
	cursor: pointer;
}

.nav-profile ul li:first-child {
	border-bottom: 1px solid rgb(216, 211, 211);
}

.nav-profile ul li#create {
	border-bottom: 1px solid rgb(216, 211, 211);
	display: none;
}

.nav-profile ul li#create i {
	color: #0ead69;
}

.nav-profile ul li#create:hover i {
	color: white;
}

.nav-profile ul li.total-values {
	border-bottom: 1px solid rgb(216, 211, 211);
}

.nav-profile ul li.total-values i {
	margin: 0 10px 0 16px;
}

.nav-profile ul li.total-values span {
	margin: 0 0px 0 -3px;
}

.nav-profile ul li.total-values span h6 {
	display: inline-block;
	color: white;
	font-weight: bolder;
	background-color: #0ead69;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin: 0 0 0 7px;
	text-align: center;
}

.nav-profile ul li i {
	margin: 0 10px 0 20px;
	font-size: 17px;
	opacity: 0.8;
}

.nav-profile ul li:first-child i {
	color: #219ebc;
}

.nav-profile ul li:first-child:hover i {
	color: white;
}

.nav-profile ul li:last-child i {
	color: orangered;
}

@keyframes rotateX {
	0% {
		transform: scaleY(0);
	}
	80% {
		transform: scaleY(1.1);
	}
	100% {
		transform: scaleY(1);
	}
}

.navbar-container .right .icon-style span.visible {
	visibility: hidden !important;
}

/* media query start  */
@media screen and (max-width: 1300px) {
	.navbar-container .middle {
		margin-left: 0px;
	}
}

@media screen and (min-width: 1200px) {
	.navbar-container .right .icon-style span {
		margin: 0 25px 0 0;
	}
	.nav-profile ul li.total-values {
		display: none;
	}

	.right .icon-style span i .notification-count {
		right: -37px !important;
	}
}

@media screen and (max-width: 650px) {
	.navbar-container .left {
		width: 240px;
	}

	.navbar-container .nav-right-container {
		display: flex;
		justify-content: flex-end;
		width: fit-content;
	}

	.navbar-container .right .icon-style span#create-new-user {
		display: none;
	}

	.nav-profile ul li#create {
		display: block;
	}

	.navbar-container .right .icon-style {
		margin: 0 30px 0 0;
	}

	.navbar-container .right .icon-style span:nth-child(2) {
		margin: 0 30px 0 0;
	}
}

@media screen and (max-width: 540px) {
	.navbar-container .left h2 {
		transform: scale(0.7, 1.7);
		margin: 0 0px 0 -13px;
	}

	.navbar-container .right .icon-style {
		margin: 0 20px 0 0;
	}
	.navbar-container .right .icon-style span:nth-child(2) {
		margin: 0 20px 0 0;
	}
}

@media screen and (max-width: 430px) {
	.navbar-container {
		padding: 5px 0;
	}

	.navbar-container .left img {
		max-width: 50px;
		max-height: 50px;
	}

	.navbar-container .right .icon-style {
		margin: 0 10px 0 0;
	}

	.navbar-container .right .icon-style span:nth-child(2) {
		margin: 0 10px 0 0;
	}
}

@media screen and (max-width: 370px) {
	.navbar-container .left h2 {
		transform: scale(0.6, 1.5);
		margin: 0 0 0px -23px;
	}
}

/* media query for responsive box  */
@media screen and (max-width: 650px) {
	.right .notification-container#for-message {
		right: -180px;
	}
}

@media screen and (max-width: 600px) {
	.right .notification-container#for-appointment {
		right: -100px;
	}
}

@media screen and (max-width: 550px) {
	.right .notification-container#for-message {
		right: -160px;
	}
}

@media screen and (max-width: 450px) {
	.navbar-container .left > img {
		display: none;
	}

	.right .notification-container#for-message {
		right: -145px;
		width: 374px !important;
	}

	.right .notification-container#for-appointment {
		right: -85px;
		width: 374px !important;
	}

	.right .notification-container .notification-display {
		padding: 5px 2px;
	}
}

@media screen and (max-width: 400px) {
	.right .notification-container {
		width: 300px !important;
	}
	.right .notification-container .notification-display img {
		display: none;
	}
}
