.customize-login-page-container {
	background-color: white;
	border-radius: var(--card-border-radius);
	box-shadow: var(--box-shadow);
	width: 100%;
	height: 100%;
}

.customize-login-page-container > .header {
	display: flex;
	justify-content: space-between;
	padding-top: 35px;
}

.customize-login-page-container > .header > h5 {
	margin: 0;
	word-spacing: 5px;
	padding: 0 0 0 20px;
	border-left: 5px solid #3366ff;
	align-self: center;
	opacity: 0.9;
}

.customize-login-page-container > .customize-login-page-wrapper {
	padding-bottom: 20px;
	overflow-y: scroll;
	height: 68vh;
	margin: 0 4px;
	margin-top: 20px;
}

/* changing-background-img style start  */
.customize-login-page-wrapper > .changing-background-img {
	margin-bottom: 20px;
}

.customize-login-page-wrapper > .changing-background-img > .title {
	margin-left: 25px;
	display: flex;
	align-items: center;
}

.customize-login-page-wrapper > .changing-background-img > .title > i {
	color: #ff9f1c;
	margin-right: 10px;
	font-size: 20px;
}

.customize-login-page-wrapper > .changing-background-img > .title > span {
	font-weight: 500;
	font-size: 16px;
}

.customize-login-page-wrapper .changing-background-img > .preview-container {
	width: 400px;
	height: 200px;
	border-radius: 5px;
	overflow: hidden;
	margin: 10px 0 0 25px;
	outline: 2px solid rgb(189, 185, 185);
}

.customize-login-page-wrapper
	.changing-background-img
	> .preview-container
	> img {
	object-fit: fill;
	width: 100%;
	height: 100%;
}

.customize-login-page-wrapper
	.changing-background-img
	> .input-field-container {
	margin: 15px 0 0 25px;
}

.customize-login-page-wrapper
	.changing-background-img
	> .input-field-container
	> label {
	background-color: #0ead69;
	padding: 8px 20px;
	color: white;
	cursor: pointer;
	border-radius: 5px;
	font-weight: 500;
}

.customize-login-page-wrapper .changing-background-img > .btn-container {
	margin: 15px 0 0 25px;
}

.customize-login-page-wrapper
	.changing-background-img
	> .btn-container
	> button {
	padding: 8px 20px;
	color: white;
	cursor: pointer;
	border-radius: 5px;
	font-weight: 500;
}

.customize-login-page-wrapper
	.changing-background-img
	> .btn-container
	> button:focus {
	box-shadow: none;
}

.customize-login-page-wrapper
	.changing-background-img
	> .btn-container
	> button:first-child {
	margin-right: 20px;
}
/* changing-background-img style end */
