.message-box .chat-box-container {
	display: flex;
	flex-direction: column;
	margin: 10px 0 0 0;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 69vh;
}

.chat-box-container .message-info {
	display: flex;
	align-items: flex-end;
	max-width: 80%;
	width: fit-content;
	padding: 10px 17px;
}

.chat-box-container .message-info.own {
	display: flex;
	flex-direction: row-reverse;
	align-self: flex-end;
}

.chat-box-container .message-info.other {
	align-self: flex-start;
}

.chat-box-container .message-info img {
	width: 38px;
	min-width: 38px;
	height: 38px;
	min-height: 38px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	overflow: hidden;
	object-fit: cover;
	object-position: center;
	cursor: pointer;
	outline: 2px solid black;
}

.chat-box-container .message-info .message {
	border-radius: var(--card-border-radius);
}

.chat-box-container .own .message {
	background-color: #bcfdb0;
	margin: 0 10px 0 0;
}
.chat-box-container .other .message {
	background-color: white;
	margin: 0 0 0 10px;
}

.chat-box-container .message-info .message .message-text {
	font-weight: 400;
	text-align: justify;
	padding: 10px 10px 0 10px;
	font-weight: 500;
}

.chat-box-container .message-info .message #time {
	font-size: 10px;
	padding: 0 5px;
	float: right;
	clear: both;
}

/* attachment view style start  */
.chat-box-container .message-info #attachment {
	display: flex;
	flex-direction: column;
	width: fit-content;
	height: fit-content;
	border-radius: 10px;
	overflow: hidden;
}

.chat-box-container .message-info .attachment-file {
	display: flex;
	justify-content: center;
}

.chat-box-container .message-info #attachment img {
	width: 200px;
	height: 200px;
	aspect-ratio: 1/1;
	overflow: hidden;
	object-fit: 100% contain;
	object-position: center;
	border-radius: 10px;
	cursor: no-drop;
}

.chat-box-container .message-info #attachment audio {
	width: 350px;
	height: 50px;
	overflow: hidden;
	margin-bottom: 5px;
}

.chat-box-container .message-info #attachment audio {
	transition: all 0.5s linear;
	box-shadow: 2px 2px 4px 0 #006773;
	border-radius: 7px;
}
.chat-box-container .message-info #attachment audio:hover,
audio:focus,
audio:active {
	box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
	transform: scale(1.05);
}

.chat-box-container .message-info #attachment video {
	width: 350px;
	height: 100%;
	border-radius: 10px;
	cursor: no-drop;
}

.chat-box-container .message-info #attachment a {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	color: black;
	text-decoration: none;
	font-weight: 500;
	color: #006d77;
}

.chat-box-container .message-info #attachment a:hover {
	text-decoration: underline;
}

.chat-box-container .message-info #attachment .message-text {
	padding: 5px 10px 0 10px;
}

.chat-box-container .message-info #attachment #when-image {
	width: 200px;
}

.chat-box-container .message-info #attachment #when-other {
	width: 350px;
}

/* attachment view style end  */

/* media query start  */
@media screen and (max-width: 600px) {
	.message-box .chat-box-container {
		height: 65vh;
	}
}

/* media query end  */

/* for scroll color  */
.message-box .chat-box-container::-webkit-scrollbar-thumb {
	background: #dfdbe0;
}
