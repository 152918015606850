.curr-user-info .semester-container {
	position: relative;
	width: 100%;
}

.curr-user-info .semester-container::before {
	content: "";
	position: absolute;
	right: 22px;
	top: 8px;
	z-index: 1;
	width: 8px;
	height: 8px;
	border: 2px solid #333;
	border-top: 2px solid #fff;
	border-right: 2px solid #fff;
	transform: rotate(-45deg);
	transition: 0.5s;
	pointer-events: none;
}

.curr-user-info .semester-container.active::before {
	top: 13px;
	transform: rotate(-225deg);
}

.curr-user-info .semester-container input {
	cursor: pointer;
	background-color: #fff;
	border: none;
	outline: none;
	border-radius: 5px;
	font-weight: 600;
	font-size: 18px;
}

.curr-user-info .semester-container input::placeholder {
	padding-left: 10px;
	color: #757575;
	font-weight: 500;
}

.curr-user-info .semester-container .semester-option-wrapper {
	width: 100%;
	height: fit-content;
	background-color: #fff;
	box-shadow: 0 1px 6px rgb(0 0 0 / 50%);
	position: absolute;
	bottom: 54px;
	border-radius: 5px;
	z-index: 100;
	display: none;
	overflow: hidden;
}

.curr-user-info .semester-container.active .semester-option-wrapper {
	display: block;
}

.curr-user-info .semester-container .semester-option-wrapper .semester-option {
	overflow-y: auto;
	overflow-x: hidden;
	font-weight: 500;
	max-height: 270px;
	margin-right: 3px;
	background-color: #fff;
}

.curr-user-info .semester-container .semester-option > div {
	padding: 5px 20px;
	cursor: pointer;
}

.curr-user-info .semester-container .semester-option div:hover {
	background-color: #0c96e0;
	color: #fff;
}

.curr-user-info .semester-container .semester-option div i {
	position: relative;
	top: 0px;
	font-size: 1em;
	margin: 0 10px 0 0;
}
