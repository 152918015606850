.signup-main-container {
	position: absolute;
	top: 0;
	z-index: 10;
}

.signup-main-container .signup-wrapper {
	display: flex;
	justify-content: center;
}

.signup-wrapper .signup-container {
	height: max-content;
	background-color: white;
	box-shadow: var(--box-shadow);
	border-radius: var(--card-border-radius);
}

.signup-container .left {
	display: flex;
	justify-content: center;
	align-items: center;
}

.signup-container .left img {
	width: 100%;
	height: 90%;
}

/* right section start  */
.signup-container .right h2 {
	color: var(--project-name-color);
	margin: 0;
	padding: 15px 0px 10px 0;
	text-align: center;
	font-weight: 600;
	letter-spacing: 1px;
	word-spacing: 10px;
}

.signup-container .right form {
	padding: 0px 25px 0 0;
}

.signup-container .right form input {
	font-weight: 500;
}

.signup-container .right form .group {
	display: flex;
	justify-content: space-between;
	margin: 20px 0px;
}

.signup-container .right form .signup-footer {
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
}

.signup-container .right form .year span {
	font-size: 18px;
	font-weight: 500;
	color: #383131;
	margin: 0px 17px 0 0px;
}

.signup-container .right form .btn-container {
	display: flex;
	justify-content: flex-end;
}

.signup-container .right form #increase-width {
	width: 100%;
}

.right form .btn-container button {
	padding: 7px 20px;
	font-size: 17px;
	font-weight: 500;
}

.right form .btn-container button:hover {
	transform: scale(0.97);
}

.right form .btn-container button:first-child {
	margin: 0 30px 0 0;
}
/* right section end  */

/* for scrollbar style  */
.signup-container .option::-webkit-scrollbar-thumb {
	background-color: #7400b8;
}

/* password toggle eye style start  */
.signup-container .password-field {
	position: relative;
}

.signup-container .password-field #eye {
	position: absolute;
	top: 30%;
	right: 20px;
}

.signup-container .password-field #eye i {
	opacity: 0.7;
}

/* password toggle eye style end  */

/* range container style start  */
.signup-container .right .range-container {
	display: flex;
	align-items: center;
	cursor: pointer;
	background-color: #fff;
	box-shadow: 0px 1px 5px rgb(0 0 0 / 50%);
	padding: 7px 17px;
	border-radius: 5px;
	font-weight: 600;
	font-size: 18px;
	width: 48%;
	margin-top: -5px;
	overflow: hidden;
}

.signup-container .right .range-container h6 {
	margin: 0;
	width: fit-content;
	font-weight: 600;
	font-size: 16px;
}

.signup-container .right .range-container .range-fields {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
}

.signup-container .right .range-container input {
	border: none;
	outline: none;
	width: 90px;
	text-align: center;
}
/* range container style end  */

/* media query start  */
@media screen and (max-width: 1300px) {
	.signup-container .right #academic {
		display: none !important;
		border: 1px solid red;
	}
}
@media screen and (max-width: 991px) {
	.signup-container .right form {
		padding: 10px 40px 0;
	}
}

@media screen and (max-width: 600px) {
	.signup-container .right .range-container h6 {
		display: none;
	}
}

@media screen and (max-width: 560px) {
	.signup-container .right form {
		padding: 10px 20px 0;
	}
}

@media screen and (max-width: 510px) {
	.right form .btn-container #btn-remove-pd {
		padding: 7px 7px;
	}

	.right form .btn-container #btn-remove-pd:first-child {
		margin: 0 20px 0 0;
	}
}

@media screen and (max-width: 444px) {
	.signup-container .right form .year span {
		margin-right: 5px;
	}
}

@media screen and (max-width: 420px) {
	.signup-container .right #year-hidden {
		display: none !important;
	}
}
