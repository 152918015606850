.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(to left, #5a8ee77a, #94e497a8),
		url("/public/assets/images/login-background.jpg");
	background-size: cover;
	position: relative;
}

.login-container .form-container {
	display: flex;
	align-items: center;
	box-shadow: var(--box-shadow);
	border-radius: var(--card-border-radius);
	width: 100%;
	/* From https://css.glass */
	background: rgba(254, 254, 254, 0.596);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	outline: 1px solid rgba(255, 255, 255, 0.3);
	height: 410px;
}

.login-container .form-container .left {
	display: flex;
	height: 100%;
	width: 50%;
	border-radius: 10px 0 0 10px;
	overflow: hidden;
}

.login-container .form-container .left img {
	object-fit: 100%;
	object-position: center;
}

.login-container .form-container .right {
	display: flex;
	flex-direction: column;
}

.login-container .form-container .right > .right-img-container {
	height: 88px;
	box-sizing: content-box;
	align-self: center;
}

.login-container .form-container .right > .right-img-container > img {
	object-fit: contain;
	width: 100%;
	height: 100%;
	margin-left: 16px;
}

.login-container .form-container .right > form {
	padding: 20px 40px 0;
}

.login-container .form-container .right > form input {
	font-weight: 500;
	background-color: transparent !important;
	border-radius: 5px;
	border: 1px solid #198754;
}

.login-container .form-container .right > form input:focus {
	font-weight: 500;
	background-color: transparent;
	box-shadow: none;
}

.login-container .form-container .right > form input + label {
	font-size: 15px;
}

.login-container .form-container .right > form label > #important {
	color: #f95738;
}

.form-container .right > form button {
	padding: 7px 20px;
	font-size: 17px;
	font-weight: 500;
}

.form-container .right > form button:focus {
	box-shadow: none;
}

/* password eye style start  */

.form-container .right #password-field {
	position: relative;
}

.form-container .right #password-field #eye {
	position: absolute;
	top: 30%;
	right: 20px;
}

.form-container .right #password-field #eye i {
	opacity: 0.7;
}
/* password eye style end  */

/* footer style start  */
.login-footer-container {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0px;
	padding: 0 40px;
}

.login-footer-container > footer {
	text-align: center;
	color: #fdfff1;
	font-size: 14px;
	font-weight: 300;
	word-spacing: 1.5px;
}

.login-footer-container > footer > p > a {
	color: #53e2ff;
	font-weight: 500;
	font-size: 15px;
	text-decoration: none;
	position: relative;
}

.login-footer-container > footer > p > a::after {
	content: "";
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100%;
	height: 1.5px;
	background-color: #fdfff1;
	transition: all 0.3s;
}

.login-footer-container > footer > p > a:hover::after {
	background-color: #53e2ff;
}

.login-footer-container > footer > p > #developers {
	margin-top: 5px;
	display: inline-block;
}
/* footer style end */

/* media query start  */
@media screen and (max-width: 650px) {
	.login-container .form-container-wrapper {
		margin-top: -60px;
	}

	.login-footer-container {
		padding: 0 30px;
	}
}

@media screen and (max-width: 550px) {
	.login-container .form-container-wrapper {
		margin-top: -70px;
	}

	.login-container .form-container .right > form {
		padding: 20px 25px 0;
	}

	.login-footer-container {
		padding: 0 20px;
	}
}

/* media query end  */

/* project summary style start  */
/* .login-container .project-summary-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-container .project-summary {
	position: absolute;
	bottom: 0px;
	box-shadow: var(--box-shadow);
	border-radius: 10px;
	padding: 10px !important;
	min-width: min-content;
	max-width: 992px;

	background: rgba(255, 255, 255, 0.2);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.login-container .project-summary .tab-container {
	display: flex;
}

.login-container .project-summary .tab-container h6 {
	cursor: pointer;
	font-weight: 600;
	color: #006d77;
}

.login-container .project-summary .tab-container h6.hover-link::after {
	background-color: #006d77;
	height: 3px;
}

.login-container .project-summary .tab-container h6:first-child {
	margin-right: 20px;
}

.login-container .project-summary .tab-container #underline {
	border-bottom: 3.5px solid #006d77;
}

.login-container .project-summary span {
	margin-left: 15px;
	font-weight: 600;
}

.login-container .project-summary tr {
	display: flex;
	align-items: baseline;
	margin-bottom: 5px;
}

.login-container .project-summary tr {
	display: flex;
	align-items: baseline;
}

.login-container .project-summary tr .details {
	margin-left: 7px;
	text-align: justify;
}

.login-container .project-summary tr .details::first-letter {
	font-weight: bold;
	color: #006d77;
}

.login-container .project-summary tr td:first-child {
	font-weight: 600;
}
*/
/* project summary style end */
