.customize-left-sidebar-container {
	background-color: white;
	border-radius: var(--card-border-radius);
	box-shadow: var(--box-shadow);
	width: 100%;
	height: 100%;
}

.customize-left-sidebar-container > .header {
	display: flex;
	justify-content: space-between;
	padding-top: 35px;
}

.customize-left-sidebar-container > .header > h5 {
	margin: 0;
	word-spacing: 5px;
	padding: 0 0 0 20px;
	border-left: 5px solid #3366ff;
	align-self: center;
	opacity: 0.9;
}

.customize-left-sidebar-container > .customize-left-sidebar-wrapper {
	padding-bottom: 20px;
	overflow-y: scroll;
	height: 68vh;
	margin: 0 4px;
	margin-top: 20px;
}

/* cus-header-section-title style start  */
.customize-left-sidebar-wrapper .cus-header-section-title {
	margin-left: 25px;
	display: flex;
	align-items: center;
}

.customize-left-sidebar-wrapper .cus-header-section-title > i {
	color: #ff9f1c;
	margin-right: 10px;
	font-size: 20px;
}

.customize-left-sidebar-wrapper .cus-header-section-title > span {
	font-weight: 500;
	font-size: 16px;
}
/* cus-header-section-title style end  */

/* cus-left-sidebar-changing-color style start  */
.customize-left-sidebar-wrapper > .cus-left-sidebar-changing-color {
	margin-bottom: 20px;
}

.cus-left-sidebar-changing-color > .changing-color-container {
	margin-left: 25px;
}

.cus-left-sidebar-changing-color > .changing-color-container > .section {
	margin-top: 30px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.cus-left-sidebar-changing-color > .changing-color-container > .section > span {
	font-weight: 500;
}

.cus-left-sidebar-changing-color
	> .changing-color-container
	> .section
	> .color-picker {
	position: relative;
	width: 150px;
	height: 35px;
	margin-left: 15px;
	border-radius: 5px;
	cursor: pointer;
	border: 2px solid rgb(189, 185, 185);
}

.cus-left-sidebar-changing-color
	> .changing-color-container
	> .section
	> .color-picker
	> .color-picker-component {
	position: absolute;
	left: 0;
	bottom: 60px;
	z-index: 100;
}

.cus-left-sidebar-changing-color
	> .changing-color-container
	> .section
	> .color-picker
	> .when-user-name {
	position: absolute;
	left: 0;
	top: 45px;
	z-index: 10000;
}

.cus-left-sidebar-changing-color
	> .changing-color-container
	> .section
	> .color-picker#foreground-color::before {
	content: "foreground - color";
	font-size: 12px;
	position: absolute;
	left: 0px;
	top: -18px;
	color: #264653;
	z-index: 100;
}

.cus-left-sidebar-changing-color
	> .changing-color-container
	> .section
	> .color-picker#background-color::before {
	content: "background - color";
	font-size: 12px;
	position: absolute;
	left: 0px;
	top: -18px;
	color: #264653;
	z-index: 100;
}

.cus-left-sidebar-changing-color
	> .changing-color-container
	> .section
	> button {
	padding: 0 20px;
	height: 35px;
	font-weight: 500;
	background-color: #0ead69;
	margin-left: 15px;
}

.cus-left-sidebar-changing-color
	> .changing-color-container
	> .section
	> button:focus {
	box-shadow: none;
}
/* cus-left-sidebar-changing-color style end  */
