.homepage-container .left .current-user {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	padding: 15px 10px;
	border-radius: var(--card-border-radius);
	box-shadow: var(--box-shadow);
}

.homepage-container .left .current-user img {
	width: 147px;
	min-width: 147px;
	height: 147px;
	min-height: 147px;
	aspect-ratio: 1/1;
	border-radius: 50%;
	overflow: hidden;
	object-fit: cover;
	object-position: center;
	margin: 10px 0 0 0;
	outline: 1.5px solid rgb(189, 185, 185);
	box-shadow: var(--box-shadow);
}

.homepage-container .left .current-user .info {
	width: 100%;
	margin: 10px 0 0 0;
	overflow: hidden;
}

.left .current-user .info h4 {
	margin: 0;
	text-transform: capitalize;
	letter-spacing: 0.5px;
	word-spacing: 2px;
	font-size: 1.2rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 20px;
	text-align: center;
}

.left .current-user .info div {
	text-align: center;
	padding: 0 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.left .current-user .info h6 {
	display: inline-block;
	margin: 0;
	color: #073b4c;
	font-weight: 600;
}

.left .current-user .info span {
	margin-left: 2px;
	font-weight: 500;
}

.homepage-container .left .tab-container {
	margin-top: 20px;
	background-color: white;
	border-radius: var(--card-border-radius);
	box-shadow: var(--box-shadow);
	overflow: hidden;
}

.left .tab-container span {
	display: flex;
	padding: 20.5px 0;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.left .tab-container span:hover {
	background-color: var(--hover-bg-color);
}

.left .tab-container span h5 {
	margin: 0 0 0 1.5rem;
	font-size: 17px;
}

.left .tab-container span i {
	font-size: 1.3rem;
	margin: 0 0 0 2rem;
	opacity: 0.8;
}
