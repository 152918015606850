.signup-container .right .year-container {
	display: inline-block;
	position: relative;
}

.signup-container .right .year-container input {
	cursor: pointer;
	background-color: #fff;
	border: none;
	outline: none;
	box-shadow: 0px 1px 5px rgb(0 0 0 / 50%);
	border-radius: 5px;
	font-weight: 600;
	font-size: 18px;
	width: 100% !important;
	padding: 4px 6px;
	text-align: center;
}

.signup-container .right .year-container input:focus {
	outline: 4px solid #c2daff;
}

/* date-picker modify start  */
.right .year-container .react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 1em 0.5em;
}

.right .year-container .react-date-picker__calendar {
	inset: auto auto 130% -130% !important;
}

.right .year-container .react-date-picker__wrapper {
	width: 100px;
	border: none;
}

.right .year-container .react-date-picker__button svg {
	display: none;
}

/* date-picker modify end  */
